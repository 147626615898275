//Libraries
import { useStaticQuery, graphql } from 'gatsby'

export const useImageQuery = () => {
  const cards = graphql`
    fragment cards on File {
      childImageSharp {
        fluid(maxWidth: 480, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `

  const images = useStaticQuery(graphql`
    query {
      OOALogo: file(relativePath: { eq: "identity/Logo.webp" }) {
        childImageSharp {
          fixed(width: 224, height: 39, quality: 90) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      googleMarker: file(relativePath: { eq: "contact/red-circle.webp" }) {
        ...cards
      }

      CommunityPartners: file(
        relativePath: { eq: "identity/looking-for-something-else.webp" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      PageBackground: file(
        relativePath: { eq: "identity/page-background.webp" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      ErrorPageIcon: file(relativePath: { eq: "identity/Telescope.webp" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return images
}
