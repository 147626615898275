//Types
import {
  CountiesType,
} from './z_static-types'
import { LinkObjectType } from '../src/components/z_components-types'

//This file is for certain configuration used in multiple places in the app

//This populates the sub menu for the programs/services dropdown for both mobile and desktop headers
export const ProgramsSubmenu: LinkObjectType[] = [
  { name: 'ADRC Programs', link: '/adrc-programs', id: 1 },
  {
    name: 'Adult Protective Services',
    link: '/adult-protective-services',
    id: 2,
  },
  {
    name: 'Emergency Preparedness',
    link: '/emergency-preparedness',
    id: 4,
  },
  { name: 'Homemaker', link: '/homemaker', id: 5 },

  { name: 'Intensive Respite', link: '/intensive-respite', id: 6 },
  { name: 'Legal Assistance', link: '/legal-assistance', id: 7 },
  { name: 'Nutritional Programs', link: '/nutritional-programs', id: 8 },
  { name: 'Ombudsman', link: '/ombudsman', id: 9 },
]

//This populates the sub menu for the resources dropdown for both mobile and desktop headers
export const ResourcesSubmenu: LinkObjectType[] = [
  {
    name: 'Community Partners',
    link: '/community-partners',
    id: 1,
  },
  { name: 'Announcements', link: '/announcements', id: 2 },
  { name: 'Events', link: '/events', id: 3 },
  {
    name: 'Forms and Documents',
    link: '/forms-and-documents',
    id: 4,
  },
  { name: 'Senior Centers', link: '/senior-centers', id: 5 },
]

export const Counties: CountiesType = [
  'blaine',
  'camas',
  'cassia',
  'gooding',
  'jerome',
  'lincoln',
  'minidoka',
  'twin falls',
]
