//Libraries
import React, { FunctionComponent, useContext } from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

//Hooks
import { useImageQuery } from '../../hooks/useImageQuery'
import { useVolunteerQuery } from '../../hooks/useVolunteerQuery'

//Context
import { FontSizeContext } from '../../context/fontSizeContext'

//Styles
import styles from './css/header.module.scss'

//Components
import MobileHeader from './mobileHeader'
import DesktopHeader from './desktopHeader'

const Header: FunctionComponent<{}> = () => {
  const images = useImageQuery()
  const volunteerPages = useVolunteerQuery()
  const { fontSize } = useContext(FontSizeContext)

  return (
    <>
      <div className={styles.goldBanner}
      ></div>
      <header
        className={fontSize !== 'large' ? styles.header : styles.headerLarge}
        id="header"
      >
        <Link to="/" className={styles.logo}>
          <Img fixed={images.OOALogo.childImageSharp.fixed} alt="OOA Logo"/>
        </Link>

        <DesktopHeader data={ volunteerPages } />

        <MobileHeader data={ volunteerPages } />
      </header>
    </>
  )
}

export default Header
