//Libraries
import React, { useContext } from 'react'
import Img from 'gatsby-image'

//Hooks
import { useImageQuery } from '../../hooks/useImageQuery'

//Context
import { FontSizeContext } from '../../context/fontSizeContext'

//Styles
import styles from './css/footer.module.scss'

//Components
import IconWithLabel from '../free/iconWithLabel'

const Footer: () => JSX.Element = () => {
  const images = useImageQuery()
  const { fontSize } = useContext(FontSizeContext)
  return (
    <>
      <footer
        className={fontSize !== 'large' ? styles.footer : styles.footerLarge}
        id="footer"
      >
        {/*Not finished yet */}
        <section className={styles.infoSection}>
          <div className={styles.footerDiv}>
            <Img
              className={styles.logo}
              fixed={images.OOALogo.childImageSharp.fixed}
            />
            <nav className={styles.socialIcons}>
              <a href="https://www.facebook.com/OfficeonAgingatCollegeofSouthernIdaho/" aria-label="OOA Facebook Page">
                <IconWithLabel iconName="ri-facebook-fill" />
              </a>
            </nav>
          </div>
          {/*Not finished yet */}

          <div className={styles.footerDiv}>
            <p>
              <strong>Office on Aging</strong>
            </p>
            <ul>
              <li>650 Addison Avenue West. 4th floor</li>
              <li>PO Box 1238</li>
              <li>Twin Falls, ID, 83303-1238</li>
            </ul>

            <p>
              <strong>Phone</strong>
            </p>
            <ul>
              <li>
                Local: <a href={'tel:208-736-2122'}>208-736-2122</a>
              </li>
              <li>
                Fax: <a href={'tel:208-736-2126'}>208-736-2126</a>
              </li>
            </ul>

            <p>
              <strong>Email</strong>
            </p>
            <ul>
              <li>
                <a href={'mailto:adjewell@csi.edu'}>adjewell@csi.edu</a>
              </li>
            </ul>
          </div>
        </section>

        <section className={styles.linkSection}>
          <div className={styles.footerDiv}>
            <p className={styles.linkList}>
              <strong>CSI QUICK LINKS</strong>
            </p>
            <ul>
              <li>
                <a href="https://www.csi.edu/">CSI Homepage</a>
              </li>
              <li>
                <a href="https://csioffice.sharepoint.com/sites/mycsi/">
                  MyCSI
                </a>
              </li>
              <li>
                <a href="https://csioffice.sharepoint.com/">CSI Connect</a>
              </li>
              <li>
                <a href="https://cm.maxient.com/reportingform.php?CollegeofSouthernID">
                  Report a Concern
                </a>
              </li>
            </ul>
          </div>

          <div className={styles.footerDiv}>
            <p className={styles.linkList}>
              <strong>COMMUNITY</strong>
            </p>
            <ul>
              <li>
                <a href="https://athletics.csi.edu/">Athletics</a>
              </li>
              <li>
                <a href="https://fineartscenter.csi.edu/">Fine Arts Center</a>
              </li>
              <li>
                <a href="https://herrett.csi.edu/">Harrett Center</a>
              </li>
              <li>
                <a href="https://www.csi.edu/recreation-center/">
                  Student Rec Center
                </a>
              </li>
            </ul>
          </div>

          <div className={styles.footerDiv}>
            <p className={styles.linkList}>
              <strong>INFORMATION</strong>
            </p>
            <ul>
              <li>
                <a href="https://www.csi.edu/about/">About</a>
              </li>
              <li>
                <a href="https://www.csi.edu/hr/employment.aspx">Careers</a>
              </li>
              <li>
                <a href="https://quondam.csi.edu/privacy.asp">Privacy</a>
              </li>
              <li>
                <a href="https://www.csi.edu/title-ix/">Title IX</a>
              </li>
              <li>
                <a href="https://www.csi.edu/accessibility/">Accessibility</a>
              </li>
            </ul>
          </div>
        </section>

        <hr />

        <div className={styles.footerDiv}>
          <p className={styles.copywrite}>
            © 1997-2020 College of Southern Idaho
          </p>

          <p className={styles.policies}>
            <a href="https://www.csi.edu/contact/">Contact</a> |
            <a href="https://quondam.csi.edu/privacy.asp"> Policies</a>
          </p>
        </div>
      </footer>
    </>
  )
}

export default Footer
