//Libraries
import React, { FunctionComponent } from 'react'
import cs from 'classnames'

//Types
import { IconWithLabelTypes } from '../z_components-types'

//Styles
import styles from './css/iconstyles.module.scss'

const IconWithLabel: FunctionComponent<IconWithLabelTypes> = ({
  iconName,
  iconLabel,
  orientation,
  state,
  setState,
  hasDropdown,
}) => (
  <div
    className={
      orientation === 'row' || !orientation
        ? styles.containerRow
        : styles.containerColumn
    }
    onClick={() => {
      state !== undefined && setState !== undefined ? setState(!state) : ''
    }}
  >
    <div className={styles.iconContainer}>
      <i className={cs(iconName, styles.icon)}></i>
    </div>
    <span
      className={cs(styles.iconLabel, hasDropdown ? styles.hasDropdown : '')}
    >
      {iconLabel}
    </span>
  </div>
)

export default IconWithLabel
