//Libraries
import { useStaticQuery, graphql } from 'gatsby'

export const useVolunteerQuery = () => {
  const volunteerPages = useStaticQuery(graphql`
    query volunteerPages {
      seniorCenters: allMarkdownRemark(
        filter: { frontmatter: { template_key: { eq: "senior-centers" } } }
      ) {
        edges {
          node {
            frontmatter {
              name
            }
            fields {
              slug
            }
          }
        }
      }

      communityPartners: allMarkdownRemark(
        filter: { frontmatter: { template_key: { eq: "community-partner" } } }
      ) {
        edges {
          node {
            frontmatter {
              name
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `)
  return volunteerPages
}
