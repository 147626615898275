//Libraries
import React, { FunctionComponent, useState } from 'react'
import { Link } from 'gatsby'
import cs from 'classnames'

//Styles
import styles from './css/header.module.scss'

//Components
import IconWithLabel from '../free/iconWithLabel'
import DropdownItems from './dropdownItems'
import SearchBox from '../search/searchBox'
import { buildMenu } from './desktopHeader'

//Types
import { HeaderProps } from './z_free-components-types'

//Static
import { ResourcesSubmenu, ProgramsSubmenu } from '../../../static/localData'

const MobileHeader: FunctionComponent<HeaderProps> = ( { data } ) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const [isSubmenuOpen, setIsSubmenuOpen] = useState({
    programs: false,
    resources: false,
    volunteer: false,
  })

  const { communityPartners, seniorCenters } = data

  const communityPartersMenuObject = buildMenu( communityPartners )
  const seniorCentersMenuObject = buildMenu( seniorCenters )

  const volunteerMenuObject = {
    'Senior Centers': seniorCentersMenuObject,
    'Community Partners': communityPartersMenuObject,
    'AAA Programs': [
      {
        name: 'Ombudsman',
        link: '/ombudsman#volunteer',
        id: 'Ombudsman-0',
      },
    ]
  }

  return (
    <>
      <div className={styles.onlyMobile}>
        <IconWithLabel
          iconName="ri-menu-line"
          iconLabel="MENU"
          orientation="column"
          state={isMenuOpen}
          setState={setIsMenuOpen}
        />
      </div>
      <div className={cs(styles.dropdown, isMenuOpen ? styles.open : '')}>
        <ul className={styles.mainLinks}>
          <li>
            <Link to="/">
              <IconWithLabel iconName="ri-home-line" iconLabel="Home" />
            </Link>
          </li>

          {/*These are navigation items that have a sub menu */}
          <li
            onClick={() =>
              setIsSubmenuOpen({
                ...isSubmenuOpen,
                programs: !isSubmenuOpen.programs,
              })
            }
            className={cs(
              styles.hasDropdownMobile,
              isSubmenuOpen.programs ? styles.open : ''
            )}
          >
            <IconWithLabel
              iconName="ri-clipboard-line"
              iconLabel="Programs and Services"
              hasDropdown={true}
            />
            <DropdownItems
              navItems={ProgramsSubmenu}
              state={isMenuOpen}
              setState={setIsMenuOpen}
            />
          </li>

          <li
            onClick={() =>
              setIsSubmenuOpen({
                ...isSubmenuOpen,
                resources: !isSubmenuOpen.resources,
              })
            }
            className={cs(
              styles.hasDropdownMobile,
              isSubmenuOpen.resources ? styles.open : ''
            )}
          >
            <IconWithLabel
              iconName="ri-book-2-line"
              iconLabel="Resources"
              hasDropdown={true}
            />
            <DropdownItems
              navItems={ResourcesSubmenu}
              state={isMenuOpen}
              setState={setIsMenuOpen}
            />
          </li>

          <li
            onClick={() =>
              setIsSubmenuOpen({
                ...isSubmenuOpen,
                volunteer: !isSubmenuOpen.volunteer,
              })
            }
            className={cs(
              styles.hasDropdownMobile,
              isSubmenuOpen.volunteer ? styles.open : ''
            )}
          >
            <IconWithLabel
              iconName="ri-user-heart-line"
              iconLabel="Volunteer"
              hasDropdown={true}
            />
            <DropdownItems
              submenuItems={volunteerMenuObject}
              navItems={[]}
              state={isMenuOpen}
              setState={setIsMenuOpen}
            />
          </li>
          {/*These are navigation items that have a sub menu */}

          <li>
            <Link to="/about">
              <IconWithLabel iconName="ri-community-line" iconLabel="About" />
            </Link>
          </li>

          <li>
            <Link to="/contact">
              <IconWithLabel iconName="ri-chat-4-line" iconLabel="Contact Us" />
            </Link>
          </li>
        </ul>

        {/*Not finished yet */}
        <ul>
          <li>
            <button className={styles.searchButton}>
              <IconWithLabel
                iconName="ri-search-line"
                iconLabel="Search"
                hasDropdown={true}
                state={isSearchOpen}
                setState={setIsSearchOpen}
              />
            </button>

            <SearchBox isOpen={isSearchOpen} id="2"/>
          </li>
        </ul>
        {/*Not finished yet */}
      </div>
    </>
  )
}

export default MobileHeader
