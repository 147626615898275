//Libraries
import React, { FunctionComponent } from 'react'

//Styles
import styles from './css/layout.module.scss'

//Components
import { LayoutTypes } from '../z_components-types'

const Layout: FunctionComponent<LayoutTypes> = ({ children }) => {
  return (
    <main id="layout" className={styles.layout}>
      {children}
    </main>
  )
}

export default Layout
