//Libraries
import React, {
  useState,
  createContext,
  useEffect,
  FunctionComponent,
  Context,
  ReactNode,
} from 'react'

const FontSizeContext: Context<{
  fontSize: string | null
  handleFontSize: (size: string) => void
}> = createContext(null)

interface FontSizeProviderTypes {
  children: ReactNode
}

const FontSizeProvider: FunctionComponent<FontSizeProviderTypes> = ({
  children,
}) => {
  if (
    typeof Storage !== 'undefined' &&
    localStorage.getItem('userFontSize') === null
  ) {
    localStorage.setItem('userFontSize', 'regular')
  }
  const [fontSize, setFontSize] = useState(
    typeof Storage !== 'undefined'
      ? localStorage.getItem('userFontSize')
      : 'regular'
  )

  useEffect(() => {
    localStorage.setItem('userFontSize', fontSize ?? '')

    document.documentElement.style.setProperty(
      '--font-size',
      fontSize === 'small' ? '14px' : fontSize === 'regular' ? '16px' : '20px'
    )
  }, [fontSize])

  const handleFontSize: (size: string) => void = size => {
    setFontSize(size)
  }

  return (
    <FontSizeContext.Provider value={{ fontSize, handleFontSize }}>
      {children}
    </FontSizeContext.Provider>
  )
}

export { FontSizeProvider, FontSizeContext }
