//Libraries
import React, { FunctionComponent, useState } from 'react'
import { Link } from 'gatsby'

//Styles 
import styles from './css/header.module.scss'

//Types
import { DropdownItemTypes, LinkObjectType } from '../z_components-types'

const DropdownItems: FunctionComponent<DropdownItemTypes> = ({
  navItems,
  submenuItems,
  setState,
  state,
}) => {
  return (
    <ul>
      {submenuItems && Object.keys(submenuItems).map( ( key ) => {
        return (
          <li className={styles.submenu} key={key}>
            <p>{key}</p>
            <Submenu submenuItems={submenuItems[key]} />
          </li>
        );
      } ) }
      {navItems.map(navItem => (
        <li key={navItem.id}>
          <Link onClick={state && setState ? () => setState(!state) : () => null} to={navItem.link}>
            {navItem.name}
          </Link>
        </li>
      ))}
    </ul>
  );
}

const Submenu: FunctionComponent<{ [key: string]: LinkObjectType[] }> = ( { submenuItems } ) => {
  return (
    <ul className={styles.submenuContainer}>
      {submenuItems.map( ( item: any ) => {
        return (
          <li key={item.id}>
            <Link to={item.link}>{item.name}</Link>
          </li>
        )
      })}
    </ul>
  )
}

export default DropdownItems
