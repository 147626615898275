//Libraries
import React, { FunctionComponent, useState } from 'react'
import { Link } from 'gatsby'

//Styles
import styles from './css/header.module.scss'

//Components
import IconWithLabel from '../free/iconWithLabel'
import DropdownItems from './dropdownItems'
import SearchBox from '../search/searchBox'

//Types
import { HeaderProps, SlugType } from './z_free-components-types'
import { LinkObjectType } from '../z_components-types'

//Static
import { ResourcesSubmenu, ProgramsSubmenu } from '../../../static/localData'

export const buildMenu: ( data: { edges: SlugType[] } ) => LinkObjectType[] = ( data ) => {
  const builtMenu: LinkObjectType[] = []

  data.edges.forEach( ( item: SlugType, index: number ) => {
    const { fields, frontmatter } = item.node
    builtMenu.push( {
      name: frontmatter.name,
      link: `${fields.slug}#volunteer`,
      id: `${ frontmatter.name }-${ index }`,
    } )
  } )

  return builtMenu;
}

const DesktopHeader: FunctionComponent<HeaderProps> = ( { data } ) => {
  const { communityPartners, seniorCenters } = data

  const communityPartersMenuObject = buildMenu( communityPartners )
  const seniorCentersMenuObject = buildMenu( seniorCenters )

  const volunteerMenuObject = {
    'Senior Centers': seniorCentersMenuObject,
    'Community Partners': communityPartersMenuObject,
    'AAA Programs': [
      {
        name: 'Ombudsman',
        link: '/ombudsman#volunteer',
        id: 'Ombudsman-0',
      },
    ]
  }

  const [openSearch, setOpenSearch] = useState(false)

  const focusSearch: () => void = () => {
    document.getElementsByName('search-input')[0].focus()
  }
  return (
    <nav>
      <Link to="/">Home</Link>

      {/*These div's are navigation items with dropdowns*/}
      <div className={styles.linkWithDropdown}>
        <span className={styles.hasDropdownDesktop}>Programs/Services</span>
        <DropdownItems navItems={ProgramsSubmenu} />
      </div>

      <div className={styles.linkWithDropdown}>
        <span className={styles.hasDropdownDesktop}>Resources</span>
        <DropdownItems navItems={ResourcesSubmenu} />
      </div>

      <div className={styles.linkWithDropdown}>
        <span className={styles.hasDropdownDesktop}>Volunteer</span>
        <DropdownItems navItems={ [] } submenuItems={ volunteerMenuObject } />
      </div>
      {/*These div's are navigation items with dropdowns*/}

      <Link to="/about">About</Link>
      <Link to="/contact">Contact Us</Link>
      {/*Not finished yet */}
      <div className={styles.linkWithDropdown}>
        <button className={styles.searchButton} onClick={focusSearch}>
          <IconWithLabel
            iconName="ri-search-line"
            iconLabel="Search"
            orientation="column"
            hasDropdown={true}
            state={openSearch}
            setState={setOpenSearch}
          />
        </button>

        <span>
          <SearchBox isOpen={openSearch} id="1"/>
        </span>
      </div>
      {/*Not finished yet */}
    </nav>
  )
}

export default DesktopHeader
