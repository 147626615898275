//Libraries
import React, {
  FunctionComponent,
  useState,
  useContext,
  KeyboardEvent,
  ChangeEvent,
} from 'react'
import cs from 'classnames'

//Context
import { FontSizeContext } from '../../context/fontSizeContext'

//Types
import { SearchBoxTypes } from '../z_components-types'

//Styles
import styles from './css/search.module.scss'

const SearchBox: FunctionComponent<SearchBoxTypes> = ({ isOpen, id }) => {
  const { fontSize } = useContext(FontSizeContext)
  const [searchValue, setSearchValue] = useState('')

  const handleEnter: (e: KeyboardEvent) => void = e => {
    if (e.key === "Enter") {
      document.location.href = `/search?q=${searchValue}`
    }
  }

  const handleChange: (e: ChangeEvent<HTMLInputElement>) => void = e => {
    setSearchValue(e.target.value.replace(/<[^>]+>/g, ''))
  }

  const handleClick: () => void = () => {
    document.location.href = `/search?q=${searchValue}`
  }

  return (
    <div
      style={{
        top: fontSize === 'small' ? '6rem' : '',
      }}
      className={cs(styles.search, isOpen && styles.open)}
    >
      <div className="input-group mb-3">
        <label htmlFor={`search-input${id}`}>Search</label>
        <input
          id={`search-input${id}`}
          name="search-input"
          type="text"
          className="form-control"
          placeholder="Search"
          aria-describedby="button-addon2"
          maxLength={30}
          value={searchValue}
          onChange={handleChange}
          onKeyUp={handleEnter}
        />
        <div className="input-group-append">
          <button
            className="btn btn-outline-secondary"
            type="button"
            id={`button-addon${id}`}
            onClick={handleClick}
          >
            <strong>Go</strong>
          </button>
        </div>
      </div>
    </div>
  )
}

export default SearchBox
